import { distanceParser } from '@shopopop/backoffice-frontend-utils';
import { Card, Col, ConfigProvider, Flex, Form, Row, Select, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PickupPointCardProps } from '../../interfaces/PickupPointCardProps';
import EmptyNoResult from '../Empty/EmptyNoResult';

const { Text } = Typography;

function PickupPointCard({ readMode, distance, unit, options, distanceError, handleSelectDrive, handleSearchDrive, distanceLoading }: PickupPointCardProps) {
  const { t } = useTranslation();
  const [filteredOptions, setFilteredOptions] = useState(options);
  // @ts-expect-error - to -resolve
  const formattedDistance = distanceParser({ value: distance, unit });

  useEffect(() => {
    setFilteredOptions(options.slice(0, 5));
  }, [options]);

  return (
    <ConfigProvider renderEmpty={() => <EmptyNoResult imageHeight={41} />}>
      <Card title={t('ORDER_PUP_TITLE')}>
        {readMode ? (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Text type="secondary">
                {/*
                  // @ts-expect-error - to resolve */}
                {t('PUP_FIELD')} : <Text>{options[0].label}</Text>
              </Text>
            </Col>
            {formattedDistance && (
              <Col xs={24} md={12}>
                {distanceError && (
                  <Flex vertical>
                    <Text type={'danger'}>
                      {t('DELIVERY_DISTANCE')} : {formattedDistance}
                    </Text>
                    <Text type={'danger'}>{t('DISTANCE_TOO_LONG')}</Text>
                  </Flex>
                )}
                {!distanceError && (
                  <Text>
                    <Text type={'secondary'}>{t('DELIVERY_DISTANCE')} :</Text>
                    <Text> {formattedDistance}</Text>
                  </Text>
                )}
              </Col>
            )}
          </Row>
        ) : (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              {/*
                // @ts-expect-error - to resolve */}
              <Form.Item
                label={t('PUP_FIELD')}
                name="pickupPoint"
                rules={[
                  {
                    required: true,
                    message: t('PUP_REQUIRED'),
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
                validateStatus={!distanceLoading && distanceError ? 'error' : undefined}
                help={!distanceLoading && distanceError ? t('DISTANCE_TOO_LONG') : undefined}
              >
                <Select
                  labelInValue
                  showSearch
                  onSearch={handleSearchDrive}
                  onSelect={(value) => {
                    handleSelectDrive(value);
                  }}
                  options={filteredOptions}
                  optionFilterProp="label"
                  placeholder={t('SELECT_PUP_FROM_LIST')}
                  style={{ width: '100%' }}
                  data-testid="pickupPoint"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              {formattedDistance && (
                <Text type="secondary">
                  {t('DELIVERY_DISTANCE')} :
                  {/*
                     // @ts-expect-error - to resolve */}
                  <Text type={distanceError ? 'danger' : undefined}> {distanceLoading ? <Spin /> : formattedDistance}</Text>
                </Text>
              )}
            </Col>
          </Row>
        )}
      </Card>
    </ConfigProvider>
  );
}

export default PickupPointCard;
